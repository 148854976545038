import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import AppNavMenu from './AppNavMenu';
import './AppNav.scss';
import { FaCaretDown, FaCheck, FaTimes } from 'react-icons/fa';
import { setCurrentTeam } from '../../r-store/team/actions';
import { TABS } from '../../AppTabs';
import { orgAndTeamData } from '../../helpers/data';
import { isString } from 'lodash';
import FlexSpacer from '../FlexSpacer/FlexSpacer';
import { CButton } from '@coreui/react';
import {
  clearImpersonate,
  impersonateUserID,
} from '../../r-store/impersonate/actions';

const AppNav = ({ teamData, numPlayers, numTeams, orgData, match }) => {
  const orgTeamData = orgAndTeamData({ orgData, teamData });
  const dispatch = useDispatch();
  const { tier: impersonatedTier, userID: impersonatedUserID } = useSelector(
    (state) => state?.ImpersonateState || {}
  );
  // dispatch(impersonateUserID('abcdefg'));

  return (
    <div className="header-sticky">
      <header
        id="header"
        className="u-header u-header--modern u-header--bordered"
      >
        {((isString(impersonatedTier) && impersonatedTier.length > 0) ||
          (isString(impersonatedUserID) && impersonatedUserID.length > 0)) && (
          <div className="header-super-admin-impersonating">
            <div>Super Admin Impersonating</div>
            <FlexSpacer />
            {isString(impersonatedTier) && impersonatedTier.length > 0 && (
              <div>Tier: {impersonatedTier}</div>
            )}
            {isString(impersonatedUserID) && impersonatedUserID.length > 0 && (
              <div>UserID: {impersonatedUserID}</div>
            )}
            <CButton
              className="header-super-admin-impersonating-close-button"
              onClick={() => {
                dispatch(clearImpersonate());
              }}
            >
              <FaTimes />
            </CButton>
          </div>
        )}
        <div className="u-header__section">
          <div id="logoAndNav" className="container-fluid">
            <nav className="js-mega-menu navbar navbar-expand-lg u-header__navbar hs-menu-initialized hs-menu-horizontal">
              <div className="left-menu-wrapper">
                <a
                  className="navbar-brand u-header__navbar-brand"
                  href="/"
                  aria-label="Space"
                >
                  <img
                    className="u-header__navbar-brand-default six-tool-logo"
                    src="/img/logo.png"
                    alt="Logo"
                  />
                  <img
                    className="u-header__navbar-brand-mobile six-tool-logo"
                    src="/img/logo.png"
                    alt="Logo"
                  />
                </a>
                {match?.path?.startsWith(TABS.COACH_APP_HOME.path) && (
                  <>
                    <div className="team-details-line"></div>
                    <div className="team-details-wrapper">
                      <Link
                        to={TABS.COACH_TEAMS.path}
                        className="selected-team-wrapper"
                      >
                        <img src={orgTeamData.imageUrl} />
                        <div className="team-details-text">
                          <div className="team-details-name">
                            {`${
                              orgTeamData.hasTeam === true
                                ? `${orgTeamData.name} Team`
                                : _.isString(orgTeamData.name) &&
                                  orgTeamData.name.length > 0
                                ? `${orgTeamData.name} Org.`
                                : 'All Teams in Org.'
                            }`}
                          </div>
                          {orgTeamData.hasTeam !== true && (
                            <div className="team-details-team">
                              {`${numTeams} Team${numTeams === 1 ? '' : 's'}`}
                            </div>
                          )}
                          <div className="team-details-players">
                            {`${numPlayers} Player${
                              numPlayers === 1 ? '' : 's'
                            }`}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                )}
              </div>
              <AppNavMenu />
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    teamData: state.DataTeamState,
    orgData: state.DataOrganizationState,
    numPlayers: (state.DataPlayerState?.data || []).length,
    numTeams: (state.DataTeamState?.data || []).length,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppNav));
