import React, { Component, useState, useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';

import AppRoute from './pages/component/AppRoute';
import CoachLayout from './pages/component/layout/CoachLayout';
import AdminLayout from './pages/component/layout/AdminLayout';
import PublicLayout from './pages/component/layout/PublicLayout';
import _ from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import './r-scss/style.scss';
import './App.css';
import './Custom.css';
import { AMBASSADOR_LINKS, TABS } from './AppTabs';
import AppReducer from './AppReducer';
import Page404 from './r-views/pages/page404/Page404';
import AmbassadorRedirect from './pages/component/AmbassadorRedirect/AmbassadorRedirect';
import BlankLayout from './pages/component/layout/BlankLayout';
import EmbedLayout from './pages/component/layout/EmbedLayout';
import SuperAdminLayout from './pages/component/layout/SuperAdminLayout';
import { paramFlags, removeParamFlags } from './config';
import { isFunction, isNil, isArray } from 'lodash';

function ScrollToTop() {
  const { pathname, search } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const params = new URLSearchParams(search);
    let flagsToRemove = new Set();
    (Object.values(paramFlags || {}) || []).forEach(
      ({ flag, onFlagged, clearAllParams, clearParams }) => {
        const paramValue = params.get(flag);
        if (!isNil(paramValue)) {
          if (isFunction(onFlagged)) {
            if (onFlagged(paramValue)) {
              flagsToRemove.add(flag);
            }
          }
          if (clearAllParams === true) {
            for (const [key, value] of params) {
              flagsToRemove.add(key);
            }
          } else if (isArray(clearParams) && clearParams.length > 0) {
            clearParams.forEach((p) => flagsToRemove.add(p));
          }
        }
      }
    );
    if (flagsToRemove.size > 0) {
      removeParamFlags([...flagsToRemove]);
    }
  }, [search]);
  return null;
}

const App = () => {
  // const types = [
  //   ACCESS_LEVELS.SUPER.key,
  //   ACCESS_LEVELS.OWNER.key,
  //   ACCESS_LEVELS.COACH.key,
  //   ACCESS_LEVELS.PLAYER.key,
  //   ACCESS_LEVELS.EVENT_PARTNER.key,
  // ];
  // const tiers = [
  //   SUBSCRIPTION_TIER.FREE.key,
  //   SUBSCRIPTION_TIER.REGULAR.key,
  //   SUBSCRIPTION_TIER.PREMIUM.key,
  // ];
  // const actions = Object.keys(ACCESS_ACTIONS);

  // actions.forEach((action) => {
  //   tiers.forEach((tier) => {
  //     types.forEach((type) => {
  //       const { canDo, threshold } = canDoAction({
  //         action,
  //         type: [type],
  //         tier,
  //       });
  //       console.log(`[${action}] ${tier} - ${type} === ${canDo}.${threshold}`);
  //     });
  //   });
  // });

  return (
    <>
      <BrowserRouter>
        <AppReducer />
        <ScrollToTop />

        <Switch>
          {Object.entries(TABS).map(([key, value], index) => {
            if (_.isNil(value.component)) {
              if (_.isString(value.redirect) && value.redirect.length > 0) {
                const isExternalUrl = value.redirect.startsWith('http');

                return (
                  <Route
                    key={`app-route-${index}-${value.redirect.replace(
                      '/',
                      ''
                    )}`}
                    exact
                    path={value.path}
                    render={() => {
                      if (isExternalUrl) {
                        window.location.href = value.redirect;
                        return null; // To prevent the component from rendering
                      }
                      return <Redirect to={value.redirect} />;
                    }}
                  />
                );
              }

              return null;
            }
            return (
              <AppRoute
                key={`app-route-${index}-${value.path.replace('/', '')}`}
                exact
                path={value.path}
                layout={
                  value.layout ||
                  (key.startsWith('OWNER')
                    ? AdminLayout
                    : key.startsWith('SUPER_ADMIN')
                    ? SuperAdminLayout
                    : key.startsWith('COACH')
                    ? CoachLayout
                    : key.startsWith('EVENT')
                    ? CoachLayout
                    : key.startsWith('BLANK')
                    ? BlankLayout
                    : key.startsWith('EMBED')
                    ? EmbedLayout
                    : PublicLayout)
                }
                component={value.component}
              ></AppRoute>
            );
          })}
          {AMBASSADOR_LINKS.map((ambassador) => {
            return (
              <Route
                key={`ambassador-${ambassador}`}
                exact
                path={`/${ambassador}`}
                render={() => <AmbassadorRedirect />}
              />
            );
          })}
          <AppRoute layout={PublicLayout} component={Page404} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
