import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
// import './team.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
// import { useUploadFile } from 'react-firebase-hooks/storage';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BootstrapForm } from 'react-bootstrap';
import { db, functions, storage } from '../../..';
// import { getStorage, storageRef } from 'firebase/storage';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { fileTimestamp } from '../../../helpers/utils';
// import Select from 'react-select';
// import { STATE_OPTION_VALUES } from '../../../../functions/src/utils/strings';
import { STATE_OPTION_VALUES } from '../../../helpers/data';
import { handleError } from '../../../helpers/errors';
import { TABS } from '../../../AppTabs';
import {
  STORAGE_TYPES,
  storageRefFolder,
  storageRefForLogo,
} from '../../../helpers/storage';
import HeaderWithBackButton from '../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { FaTrashAlt } from 'react-icons/fa';
import ConfirmModal from '../../../r-components/ConfirmModal/ConfirmModal';
import { CCardFooter } from '@coreui/react';
import {
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
} from '../../../helpers/access';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

// const storage = getStorage(firebaseApp);

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().trim().required('State is required'),
});

const iValues = {
  name: '',
  image: null,
  state: '',
  city: '',
};

const TeamEdit = ({ teams, userData, match }) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [showingOrgDetails, setShowingOrgDetails] = useState(true);
  const [runsSaved, setRunsSaved] = useState(1);
  const [initialValues, setInitialValues] = useState(iValues);
  const [imageUrl, setImageUrl] = useState('');
  const [teamData, setTeamData] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmAgainDeleteModal, setShowConfirmAgainDeleteModal] =
    useState(false);
  const { type, tier, showSubscriptionModal, goToUrl, numTeams } =
    useAccountSelectors();

  const organizationData = useSelector(
    (state) => state.DataOrganizationState || {}
  );
  const [uploadFile, uploading, snapshot, error] = useUploadFile();

  useEffect(() => {
    let team = teams.find((t) => t._id === match.params.id);
    setTeamData(team);
  }, [teams, match]);

  useEffect(() => {
    let values = {
      name: teamData?.name || '',
      image:
        _.isString(teamData?.imageUrl) && teamData?.imageUrl.length > 0
          ? teamData.imageUrl
          : null,
      city: teamData?.location?.city || '',
      state: teamData?.location?.state || '',
    };
    setInitialValues(values);
    setLogoPreview(values.image);
  }, [teamData]);
  const [logoPreview, setLogoPreview] = useState('');

  const handleInputChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    if (name === 'state') {
      setFieldValue(name, value.toUpperCase());
    } else if (name === 'city') {
      setFieldValue(name, value.charAt(0).toUpperCase() + value.slice(1));
    } else {
      setFieldValue(name, value);
    }
  };

  const handleImageChange = async (event) => {
    try {
      const file = event.target.files[0];
      let storageRef = storageRefForLogo({
        organization_id: organizationData.data._id,
        team_id: teamData._id,
        fileName: file.name,
      });
      const result = await uploadFile(storageRef, file, {
        contentType: file.type,
      });
      let downloadUrl = await storageRef.getDownloadURL();
      setImageUrl(downloadUrl);
      let _objectURL = URL.createObjectURL(file);

      setLogoPreview(_objectURL);

      try {
        (async () => {
          let width = 0;
          let height = 0;

          // Set up the FileReader onload event
          const element = new Image();

          // Set up the element onload event
          element.onload = () => {
            // Access the dimensions using the element's naturalWidth and naturalHeight properties
            width = element.naturalWidth;
            height = element.naturalHeight;

            // Do something with the dimensions
            const addMediaCallable =
              functions.httpsCallable('addMediaCallable');
            addMediaCallable({
              downloadUrl,
              organizationId: teamData.organizationId,
              teamId: teamData._id,
              width,
              height,
              usage: 'team_logo',
            });
          };

          // Assign the FileReader result to the element's src
          element.src = _objectURL;
        })();
      } catch (err) {
        handleError({
          error: err,
          internal: 'TeamEdit.js - handleImageChange',
        });
      }
    } catch (uploadErr) {
      handleError({
        error: uploadErr,
        internal: 'TeamEdit.js - uploadErr',
        toast: 'There was an error uploading file',
      });
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    checkAccess({
      action: ACCESS_ACTIONS.CREATE_TEAM,
      type,
      tier,
    }).doWithAccess(async () => {
      if (uploading) {
        toast('File is still uploading, please try again', { type: 'info' });
        return false;
      }
      setSubmitting(false);
      try {
        let updates = {
          name: values.name,
          'location.city': values.city,
          'location.state': values.state,
        };
        if (_.isString(imageUrl) && imageUrl.length > 0) {
          updates.imageUrl = imageUrl;
        }
        let response = await db
          .collection('teams')
          .doc(teamData._id)
          .update(updates);
        toast('Team updated successfully', { type: 'success' });
      } catch (error) {
        handleError({
          error,
          internal: 'TeamEdit.js - handleSubmit',
          toast: error.message,
        });
      }
    });
  };

  const closeAllModals = () => {
    setShowConfirmDeleteModal(false);
    setShowConfirmAgainDeleteModal(false);
  };

  const deleteTeamButton = () => (
    <>
      {_.isString(teamData?._id) && teamData._id.length > 1 && (
        <CButton
          size="md"
          color="danger"
          variant="ghost"
          onClick={() => {
            checkAccess({
              action: ACCESS_ACTIONS.DELETE_TEAM,
              type,
              tier,
            }).doWithAccess(() => {
              setShowConfirmDeleteModal(true);
            });
          }}
        >
          <FaTrashAlt style={{ marginRight: 6, marginTop: -2 }} size={20} />
          {`Delete Team`}
        </CButton>
      )}
    </>
  );
  const {
    canView: canDeleteTeam,
    // canDo,
    // reason,
    // step,
  } = canDoAction({
    action: ACCESS_ACTIONS.DELETE_TEAM,
    type,
    tier,
  });
  return (
    <Container className="space-1 coach-team-view">
      {userData?.isOwner === true ? (
        <>
          <ConfirmModal
            show={showConfirmDeleteModal && !showConfirmAgainDeleteModal}
            title="Confirm Delete"
            message="Are you sure you would like to delete this team from this organization? THIS CANNOT BE UNDONE."
            okButtonColor="danger"
            cancelButtonColor="primary"
            okButtonText="Delete"
            onClickCancel={closeAllModals}
            onClickConfirm={async () => {
              setShowConfirmDeleteModal(false);
              setShowConfirmAgainDeleteModal(true);
            }}
          />
          <ConfirmModal
            show={showConfirmAgainDeleteModal}
            title="Re-Confirm Delete"
            message="Are you sure you would like to delete this team? THIS IS YOUR LAST WARNING. The team will be deleted and please note this cannot be undone."
            okButtonColor="danger"
            cancelButtonColor="primary"
            okButtonText="Delete"
            onClickCancel={closeAllModals}
            onClickConfirm={async () => {
              setShowConfirmAgainDeleteModal(false);
              toast(
                'Team being deleted. It may take a minute for the team to be removed.',
                { type: 'success' }
              );
              history.push(TABS.COACH_TEAMS.path);
              let deleteTeamFromOrgCallable = functions.httpsCallable(
                'deleteTeamFromOrgCallable'
              );
              let deleteResult = await deleteTeamFromOrgCallable({
                teamId: teamData?._id,
                organizationId: teamData?.organizationId,
              });
            }}
          />
          <CRow className="edit-coach">
            <CCol lg={12}>
              <CCard>
                <HeaderWithBackButton
                  rightSide={canDeleteTeam && deleteTeamButton()}
                >
                  {`Edit ${teamData?.name} Team`}
                </HeaderWithBackButton>

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {({ isSubmitting, errors, touched, setFieldValue }) => (
                    <Form>
                      <CCardBody>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>Team Name</BootstrapForm.Label>
                          <Field
                            name="name"
                            type="text"
                            as={BootstrapForm.Control}
                            isInvalid={!!errors.name && touched.name}
                          />
                          <ErrorMessage
                            name="name"
                            component={BootstrapForm.Control.Feedback}
                            type="invalid"
                          />
                        </BootstrapForm.Group>
                        <div
                          style={{
                            display: 'flex',
                            gap: 10,
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>City</BootstrapForm.Label>
                              <Field
                                name="city"
                                type="text"
                                autoComplete="city"
                                as={BootstrapForm.Control}
                                isInvalid={!!errors.city && touched.city}
                                onChange={(event) =>
                                  handleInputChange(event, setFieldValue)
                                }
                              />
                              <ErrorMessage
                                name="city"
                                component={BootstrapForm.Control.Feedback}
                                type="invalid"
                              />
                            </BootstrapForm.Group>
                          </div>
                          <div style={{ width: 150 }}>
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>State</BootstrapForm.Label>
                              <Field
                                name="state"
                                type="text"
                                autoComplete="state"
                                as={BootstrapForm.Control}
                                isInvalid={!!errors.state && touched.state}
                                onChange={(event) =>
                                  handleInputChange(event, setFieldValue)
                                }
                              />
                              <ErrorMessage
                                name="state"
                                component={BootstrapForm.Control.Feedback}
                                type="invalid"
                              />
                            </BootstrapForm.Group>
                          </div>
                        </div>

                        <BootstrapForm.Group>
                          <BootstrapForm.Label>Image</BootstrapForm.Label>
                          <BootstrapForm.File
                            id="image"
                            name="image"
                            accept="image/*"
                            onChange={(event) => {
                              handleImageChange(event);
                              // event.target.files.length > 0 &&
                              //   event.currentTarget.nextSibling.classList.add(
                              //     'selected'
                              //   );
                              // event.currentTarget.nextSibling.textContent =
                              //   event.target.files[0].name;
                            }}
                            isInvalid={!!errors.image && touched.image}
                            feedback={errors.image}
                            style={{ display: 'block' }}
                          />
                          {uploading && <p>Uploading...</p>}
                          {logoPreview && (
                            <img
                              src={logoPreview}
                              alt="Logo Preview"
                              className="img-fluid mt-2"
                              style={{
                                width: '200px',
                                height: 'auto',
                                objectFit: 'contain',
                              }}
                            />
                          )}
                          <ErrorMessage
                            name="image"
                            component={BootstrapForm.Control.Feedback}
                            type="invalid"
                          />
                        </BootstrapForm.Group>
                      </CCardBody>
                      <CCardFooter>
                        <CButton
                          size="md"
                          color="danger"
                          variant="outline"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Cancel
                        </CButton>
                        <Button type="submit" disabled={isSubmitting}>
                          Save Changes
                        </Button>
                      </CCardFooter>
                    </Form>
                  )}
                </Formik>
              </CCard>
            </CCol>
          </CRow>
        </>
      ) : (
        <CRow className={`coach-team-view-org-section`}>
          You do not have access to this view
        </CRow>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
    teams: state.DataTeamState?.data || [],
  };
};

export default connect(mapStateToProps)(withRouter(TeamEdit));
